import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import {
  pushCourseWorkshopCompletion,
  pushPrototypeCompletion,
  getCourseCohortCompletions,
  getCohortMilestones,
  greaterThanXDays,
  fetchUserDataByUid,
  getCourseCohortData,
  getCourseResources,
  getCourseCurrentCohort,
} from "api/db";
import { auth } from "api/auth";
import { storage } from "api/firebase";
import CoursesHeader from "components/Cohort/Dashboard/CoursesHeader";
import ResourceLinks from "components/Cohort/Dashboard/ResourceLinks";

const FigmaCourse = () => {
  const [userData, setUserData] = useState(); // userData
  const [figmaIndex, setFigmaIndex] = useState(null); // index of cohort
  const [progress, setProgress] = useState(0); // progress bar percentage
  const [milestones, setMilestones] = useState(null); // milestones & completion data
  const [mentor, setMentor] = useState(false); // isMentor?
  const [uploadState, setUploadState] = useState(false); // upload state
  const [cohortData, setCohortData] = useState([]);
  const [resources, setResources] = useState([]);
  const [cohortNum, setCohortNum] = useState(null);
  const [figmaPlaceholder, setFigmaPlaceholder] = useState(null);
  const courseName = "figma";

  const [workshopSubmission, setWorkshopSubmission] = useState({
    // workshop submission
    date: 0,
    demo: null,
    description: null,
    img: "",
    prototypeName: null,
    workshop: null,
    uid: null,
  });

  useEffect(() => {
    let userData = JSON.parse(sessionStorage.getItem("userData"));
    setUserData(userData);

    if (!userData && auth && auth.currentUser && auth.currentUser.uid) {
      fetchUserDataByUid(auth.currentUser.uid).once("value", function(
        snapshot
      ) {
        setUserData(snapshot.val());
        sessionStorage.setItem("userData", JSON.stringify(snapshot.val()));
      });
    }
    let acceptedTags = ["team", "mentor"];
    if (
      (userData &&
        userData.courses &&
        userData.courses.find((s) => s.includes("f"))) ||
      (userData &&
        userData.tags &&
        acceptedTags.some((tag) => userData.tags.includes(tag)))
    ) {
      if (
        userData.tags &&
        acceptedTags.some((tag) => userData.tags.includes(tag))
        //userData.tags.includes("mentor")
      ) {
        setMentor(true);
        setProgress(100);
        getCourseCohortData(courseName).then((snapshot) => {
          setCohortData(snapshot.val());
          setCohortNum(snapshot.val().currentCohort);
        });
      } else {
        let f = userData.courses.filter((s) => s.includes("f"));
        let fcurrent = f[f.length - 1];
        let cohortNumber = parseInt(fcurrent.slice(fcurrent.length - 1));
        setCohortNum(cohortNumber);
        getCourseCurrentCohort(courseName, cohortNumber).then((snapshot) => {
          setCohortData(snapshot.val());
        });
      }
      setFigmaIndex(cohortNum);
    } else {
      navigate("/login");
    }
  }, [auth && auth.currentUser, cohortNum]);

  useEffect(() => {
    if (cohortNum != null) {
      getCohortMilestones(courseName, cohortNum).then((snapshot) => {
        let milestoneData = Object.values(snapshot.val());
        milestoneData.sort((a, b) => a.datetime - b.datetime);
        setMilestones(milestoneData);

        getCourseCohortCompletions(
          courseName,
          cohortNum,
          auth.currentUser.uid,
          function(snapshot) {
            let data = snapshot.val();

            let updatedMilestoneData = milestoneData;
            let index = updatedMilestoneData.findIndex(
              (x) => x.id == data.milestone
            );
            updatedMilestoneData[index].completed = true;
            updatedMilestoneData[index].demo = data.demo;
            updatedMilestoneData[index].img = data.img;
            updatedMilestoneData[index].submittedDate = data.date;

            setMilestones(updatedMilestoneData);

            let completedCount = 0;
            for (let i = 0; i < updatedMilestoneData.length; i++) {
              if (
                updatedMilestoneData[i] &&
                updatedMilestoneData[i].completed == true
              ) {
                completedCount++;
              }
            }

            setProgress(completedCount * (100 / 7));
          }
        );
      });
      getCourseResources(courseName).then((snapshot) => {
        setResources(snapshot.val());
      });

      let figmaLink = JSON.parse(localStorage.getItem("enlightFigmaLink"));
      setFigmaPlaceholder(figmaLink);
      setWorkshopSubmission({
        demo: figmaLink,
      })
    }
  }, [cohortNum]);

  const displayDate = (datetime) => {
    var date = new Date(datetime);
    var now = new Date();
    var tomorrow = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    let dateString = "";
    let hours = parseInt((date.getTime() - Date.now()) / (1000 * 60 * 60));
    let minutes = parseInt((date.getTime() - Date.now()) / (1000 * 60));
    let remainderMin =
      parseInt((date.getTime() - Date.now()) / (1000 * 60)) % 60;

    if (greaterThanXDays(Date.now(), date.getTime(), 1)) {
      if (
        date.getFullYear() == tomorrow.getFullYear() &&
        date.getMonth() == tomorrow.getMonth() &&
        date.getDate() == tomorrow.getDate()
      ) {
        dateString =
          "tomorrow" +
          ", " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
      } else {
        dateString =
          date.getMonth() +
          1 +
          "/" +
          date.getDate() +
          ", " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
      }
    } else if (minutes >= 5 && minutes < 60) {
      dateString = minutes + " minutes";
    } else if (hours == 1) {
      // one hour
      dateString = hours + " hour " + remainderMin + " min";
    } else if (hours >= 1) {
      // greater than 1 hour
      dateString =
        hours +
        " hours " +
        remainderMin +
        " min, " +
        date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
    } else if (Date.now() > datetime + 7200000) {
      dateString = new Date(datetime).toLocaleString();
    } else {
      dateString = "live";
    }
    return dateString;
  };

  return (
    <>
      <Helmet title="Dashboard — Prototyping with Figma | Enlight" />

      <div className="bg-white">
        <section className="mx-auto max-w-screen-xl sm:px-6 lg:px-8 mt-8">
          {userData ? (
            <div className="p-4 sm:px-2 lg:px-0">
              <CoursesHeader
                data={userData}
                cohortData={cohortData}
                isMentor={mentor}
                completion={progress}
                color="purple"
              />
              <div className="py-4 mt-2">
                {!mentor && progress == 100 ? (
                  <div className="mb-8">
                    <a
                      href={`/courses/${courseName}/certificate?user=${userData.id}`}
                      target="_blank"
                      className="text-xl tracking-tight text-purple-100 mb-4 p-2 rounded-lg bg-purple-800 hover:opacity-75 cursor-pointer"
                    >
                      View Certificate &#8250;
                    </a>
                  </div>
                ) : (
                  <h1 className="text-2xl tracking-tight text-gray-900 mb-4 font-bold">
                    What's next &#8250;
                  </h1>
                )}

                <section className="grid md:grid-cols-3 gap-4">
                  {milestones &&
                    milestones
                      .filter((a) => !a.completed && a.id != "journeymap")
                      .map((milestone, index) => (
                        <div
                          className="py-4 bg-gray-100 rounded"
                          key={milestone.id}
                        >
                          <div className="p-4">
                            <h1 className="text-gray-700 text-sm uppercase">
                              {displayDate(milestone.datetime)}
                            </h1>
                            <h1 className="tracking-tight font-bold text-gray-900 text-xl">
                              {milestone.name}
                            </h1>

                            <h2 className="text-gray-700 text-sm">
                              {milestone.description}
                            </h2>

                            {milestone.type == "event" &&
                            milestone.submissionRequired ? (
                              <div className="py-2">
                                {Date.now() > milestone.datetime - 600000 ? ( // 10 minutes before event
                                  <div>
                                    {Date.now() >
                                    milestone.datetime + 7200000 ? ( // recording two hours after event start
                                      <Link
                                        to={"/courses/figma/event"}
                                        target="_blank"
                                        state={{
                                          eventName: milestone.id,
                                          userData: userData,
                                          cohortNum: cohortNum,
                                        }}
                                        className="tracking-tight font-bold text-blue-700 text-xl mb-3 hover:opacity-75"
                                      >
                                        View recording &#8250;
                                      </Link>
                                    ) : (
                                      <a
                                        target="_blank"
                                        href={`https://zoom.enlight.nyc`}
                                        className="tracking-tight font-bold text-blue-700 text-xl mb-3 hover:opacity-75"
                                      >
                                        Join Zoom &#8250;
                                      </a>
                                    )}{" "}
                                  </div>
                                ) : (
                                  <h1 className="tracking-tight font-bold text-gray-500 text-xl mb-3">
                                    Join &#8250;
                                  </h1>
                                )}

                                {(Date.now() > milestone.datetime - 600000 || 
                                  milestone.id=="demo-day") &&
                                  !mentor ? ( // (switch to test)
                                  <>
                                    {milestone.id == "kickoff" && index == 0 ? (
                                      <>
                                        <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                          Kickoff Card
                                        </label>
                                        <input
                                          className="text-xs my-2"
                                          accept="image/*"
                                          onChange={(e) => {
                                            const image = e.target.files[0];
                                            let size = image.size / 1024 / 1024; // in MB
                                            if (size > 10) {
                                              alert("File size exceeds 10 MB");
                                            } else {
                                              const upload = storage
                                                .ref(
                                                  `/workshopSubmissions/${image.name}`
                                                )
                                                .put(image);

                                              upload.on(
                                                "state_changed",
                                                (snapshot) => {
                                                  setUploadState(true);
                                                  // console.log(snapshot);
                                                },
                                                (err) => {
                                                  // console.log(err);
                                                },
                                                () => {
                                                  storage
                                                    .ref("workshopSubmissions")
                                                    .child(image.name)
                                                    .getDownloadURL()
                                                    .then((url) => {
                                                      setUploadState(false);
                                                      setWorkshopSubmission({
                                                        ...workshopSubmission,
                                                        img: url,
                                                        workshop: milestone.id,
                                                        date: Date.now(),
                                                        demo: "",
                                                        uid:
                                                          auth.currentUser.uid,
                                                        title: milestone.name,
                                                      });
                                                    });
                                                }
                                              );
                                            }
                                          }}
                                          type="file"
                                        />
                                        {uploadState ? (
                                          <p>Uploading...</p>
                                        ) : null}
                                        <br />
                                        <button
                                          onClick={() => {
                                            if (
                                              workshopSubmission.uid &&
                                              workshopSubmission.workshop &&
                                              workshopSubmission.date &&
                                              workshopSubmission.img != ""
                                            ) {
                                              pushCourseWorkshopCompletion(
                                                figmaIndex,
                                                workshopSubmission.uid,
                                                workshopSubmission.workshop,
                                                workshopSubmission.date,
                                                workshopSubmission.demo,
                                                workshopSubmission.img,
                                                workshopSubmission.title,
                                                courseName
                                              );
                                              setWorkshopSubmission({
                                                date: 0,
                                                demo: "",
                                                img: "",
                                                workshop: "",
                                                uid: "",
                                              });
                                            } else {
                                              alert(
                                                "Please fill out all fields."
                                              );
                                            }
                                          }}
                                          className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-full text-sm mt-2"
                                        >
                                          submit
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        {index == 0 ? (
                                          <>
                                            <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                              Figma Prototype Link
                                            </label>
                                            <input
                                              placeholder={
                                                !figmaPlaceholder
                                                  ? "https://www.figma..."
                                                  : null
                                              } //FIGMA LINK PREPENDED HERE
                                              defaultValue={
                                                figmaPlaceholder
                                                  ? figmaPlaceholder
                                                  : null
                                              }
                                              className="p-1 bg-gray-100 w-full"
                                              onChange={(e) => {
                                                setWorkshopSubmission({
                                                  ...workshopSubmission,
                                                  workshop: milestone.id,
                                                  demo: e.target.value,
                                                  date: Date.now(),
                                                  uid: auth.currentUser.uid,
                                                  title: milestone.name,
                                                });
                                              }}
                                            />
                                            {milestone.id=="demo-day" ? (  //On demoday, people will also submit their prototype name and a short description to appear on the certificate
                                              <> 
                                                <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                                  The following will appear on your certificate.
                                                </label>                           
                                                <input
                                                  placeholder="Your prototype's name " 
                                                  className="p-1 bg-gray-100 w-full"
                                                  onChange={(e) => {
                                                    setWorkshopSubmission({
                                                      ...workshopSubmission,
                                                      workshop: milestone.id,
                                                      date: Date.now(),
                                                      prototypeName: e.target.value,
                                                      uid: auth.currentUser.uid,
                                                      title: milestone.name,
                                                    });
                                                  }}
                                                />
                                                <textarea
                                                  placeholder="Your prototype's description " 
                                                  className="p-1 bg-gray-100 w-full resize-y"
                                                  onChange={(e) => {
                                                    setWorkshopSubmission({
                                                      ...workshopSubmission,
                                                      workshop: milestone.id,
                                                      date: Date.now(),
                                                      description: e.target.value,
                                                      uid: auth.currentUser.uid,
                                                      title: milestone.name,
                                                    });
                                                  }}
                                                ></textarea>
                                              </>
                                            ) : null}
                                            <input
                                              className="text-xs my-2"
                                              accept="image/*"
                                              onChange={(e) => {
                                                const image = e.target.files[0];
                                                let size =
                                                  image.size / 1024 / 1024; // in MB
                                                if (size > 10) {
                                                  alert(
                                                    "File size exceeds 10 MB"
                                                  );
                                                } else {
                                                  const upload = storage
                                                    .ref(
                                                      `/workshopSubmissions/${image.name}`
                                                    )
                                                    .put(image);

                                                  upload.on(
                                                    "state_changed",
                                                    (snapshot) => {
                                                      setUploadState(true);
                                                    },
                                                    (err) => {
                                                      // console.log(err);
                                                    },
                                                    () => {
                                                      storage
                                                        .ref(
                                                          "workshopSubmissions"
                                                        )
                                                        .child(image.name)
                                                        .getDownloadURL()
                                                        .then((url) => {
                                                          setUploadState(false);
                                                          setWorkshopSubmission(
                                                            {
                                                              ...workshopSubmission,
                                                              img: url,
                                                              workshop:
                                                                milestone.id,
                                                              date: Date.now(),
                                                              uid:
                                                                auth.currentUser
                                                                  .uid,
                                                              title:
                                                                milestone.name,
                                                            }
                                                          );
                                                        });
                                                    }
                                                  );
                                                }
                                              }}
                                              type="file"
                                            />
                                            {uploadState ? (
                                              <p>Uploading...</p>
                                            ) : null}
                                            <br />
                                            <button
                                              onClick={() => {
                                                if (
                                                  workshopSubmission.uid &&
                                                  workshopSubmission.workshop &&
                                                  workshopSubmission.workshop == "demo-day" &&
                                                  workshopSubmission.date &&
                                                  workshopSubmission.demo &&
                                                  workshopSubmission.demo != "" &&
                                                  workshopSubmission.description &&
                                                  workshopSubmission.prototypeName &&
                                                  workshopSubmission.description != "" &&
                                                  workshopSubmission.prototypeName != "" &&
                                                  workshopSubmission.img &&
                                                  workshopSubmission.img != ""
                                                ) {
                                                  pushPrototypeCompletion(
                                                    figmaIndex,
                                                    workshopSubmission.uid,
                                                    workshopSubmission.workshop,
                                                    workshopSubmission.date,
                                                    workshopSubmission.demo,
                                                    workshopSubmission.description,
                                                    workshopSubmission.img,
                                                    workshopSubmission.prototypeName,
                                                    workshopSubmission.title,
                                                    courseName
                                                  );
                                                  setWorkshopSubmission({
                                                    date: 0,
                                                    demo: "",
                                                    description: "",
                                                    img: "",
                                                    prototypeName: "",
                                                    workshop: "",
                                                    uid: "",
                                                  });
                                                } else if (
                                                  workshopSubmission.uid &&
                                                  workshopSubmission.workshop &&
                                                  workshopSubmission.workshop != "demo-day" &&
                                                  workshopSubmission.date &&
                                                  workshopSubmission.demo &&
                                                  workshopSubmission.demo != "" &&
                                                  workshopSubmission.img &&
                                                  workshopSubmission.img != ""
                                                ) {
                                                  pushCourseWorkshopCompletion(
                                                    figmaIndex,
                                                    workshopSubmission.uid,
                                                    workshopSubmission.workshop,
                                                    workshopSubmission.date,
                                                    workshopSubmission.demo,
                                                    workshopSubmission.img,
                                                    workshopSubmission.title,
                                                    courseName
                                                  );
                                                  setWorkshopSubmission({
                                                    date: 0,
                                                    demo: "",
                                                    description: "",
                                                    img: "",
                                                    prototypeName: "",
                                                    workshop: "",
                                                    uid: "",
                                                  });
                                                } else {
                                                  alert(
                                                    "Please fill out all fields."
                                                  );
                                                }
                                              }}
                                              className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-full text-sm mt-2"
                                            >
                                              submit
                                            </button>
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                    {/* {milestone.completed ? (
                                    <button>
                                      <svg
                                        class="h-6 w-6 text-blue-600 ml-2"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        {" "}
                                        <path
                                          fill-rule="evenodd"
                                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                          clip-rule="evenodd"
                                        ></path>{" "}
                                      </svg>
                                    </button>
                                    ) : null} */}
                                  </>
                                ) : null}
                              </div>
                            ) : (
                              <>
                                {milestone.submissionRequired &&
                                !mentor &&
                                index == 0 ? (
                                  <>
                                    <label className="block tracking-tight text-gray-700 text-xs font-bold mb-2 mt-3">
                                      Figma Prototype Link
                                    </label>
                                    <input
                                      placeholder={
                                        figmaPlaceholder
                                          ? figmaPlaceholder
                                          : "https://www.figma..."
                                      } //FIGMA LINK PREPENDED HERE
                                      className="p-1 bg-gray-100 w-full"
                                      onChange={(e) => {
                                        setWorkshopSubmission({
                                          ...workshopSubmission,
                                          workshop: milestone.id,
                                          demo: e.target.value,
                                          date: Date.now(),
                                          uid: auth.currentUser.uid,
                                          title: milestone.name,
                                        });
                                        {
                                          milestone.id == "user-flow"
                                            ? localStorage.setItem(
                                                "enlightFigmaLink",
                                                JSON.stringify(e.target.value)
                                              )
                                            : null;
                                        }
                                      }}
                                    />
                                    <input
                                      className="text-xs my-2"
                                      accept="image/*"
                                      onChange={(e) => {
                                        const image = e.target.files[0];
                                        let size = image.size / 1024 / 1024; // in MB
                                        if (size > 10) {
                                          alert("File size exceeds 10 MB");
                                        } else {
                                          const upload = storage
                                            .ref(
                                              `/workshopSubmissions/${image.name}`
                                            )
                                            .put(image);

                                          upload.on(
                                            "state_changed",
                                            (snapshot) => {
                                              setUploadState(true);
                                              // console.log(snapshot);
                                            },
                                            (err) => {
                                              // console.log(err);
                                            },
                                            () => {
                                              storage
                                                .ref("workshopSubmissions")
                                                .child(image.name)
                                                .getDownloadURL()
                                                .then((url) => {
                                                  setUploadState(false);
                                                  setWorkshopSubmission({
                                                    ...workshopSubmission,
                                                    img: url,
                                                    workshop: milestone.id,
                                                    date: Date.now(),
                                                    uid: auth.currentUser.uid,
                                                    title: milestone.name,
                                                  });
                                                });
                                            }
                                          );
                                        }
                                      }}
                                      type="file"
                                    />
                                    {uploadState ? <p>Uploading...</p> : null}
                                    <br />
                                    <button
                                      onClick={() => {
                                        if (
                                          workshopSubmission.uid &&
                                          workshopSubmission.workshop &&
                                          workshopSubmission.date &&
                                          workshopSubmission.demo &&
                                          workshopSubmission.demo != "" &&
                                          workshopSubmission.img != ""
                                        ) {
                                          pushCourseWorkshopCompletion(
                                            figmaIndex,
                                            workshopSubmission.uid,
                                            workshopSubmission.workshop,
                                            workshopSubmission.date,
                                            workshopSubmission.demo,
                                            workshopSubmission.img,
                                            workshopSubmission.title,
                                            courseName
                                          );
                                          setWorkshopSubmission({
                                            date: 0,
                                            demo: "",
                                            img: "",
                                            workshop: "",
                                            uid: "",
                                          });
                                        } else {
                                          alert("Please fill out all fields.");
                                        }
                                      }}
                                      className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-2 rounded-full text-sm mt-2"
                                    >
                                      submit
                                    </button>
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                </section>

                {/* <section className="max-w-2xl mt-8 mx-auto shadow rounded bg-white p-8 text-center">
                  <div
                    className="py-1 bg-blue-700 hover:bg-blue-600 items-center text-blue-100 leading-none rounded-full flex inline-flex cursor-pointer"
                    role="alert"
                  >
                    <span className="flex rounded-full uppercase px-1 py-1 text-xs font-bold mr-2 ml-2">
                      NEW
                    </span>
                  </div>
                  <h1 className="text-2xl tracking-tight text-blue-900 font-bold">
                    Enjoying the cohort?
                  </h1>
                  <h2 className="text-xl tracking-tight text-blue-900 font-bold">
                    Refer your friends and get exclusive Enlight swag.
                  </h2>
                  <div className="flex justify-center">
                    <img
                      className="h-48 mt-4 text-center"
                      src="/img/courses/web-development/referral.gif"
                    />
                  </div>
                  <div className="mb-2">
                    <p className="p-2 text-blue-900 text-xl mb-2 mt-2">
                      Send them your personal referral link to sign up &#8250;
                    </p>
                    <a
                      href={`https://enlight.nyc/courses/web-development?ref=${data.id}`}
                      className="p-3 bg-blue-900 hover:bg-blue-800 text-blue-100 rounded "
                      target="_blank"
                    >
                      https://enlight.nyc/courses/web-development?ref=
                      {data.id}
                    </a>
                  </div>
                </section> */}

                <h1 className="text-2xl tracking-tight text-gray-900 mb-4 mt-6 font-bold">
                  Completed &#8250;
                </h1>

                <section className="grid md:grid-cols-3 gap-4">
                  {milestones &&
                    milestones
                      .filter((a) => a.completed == true)
                      .map((milestone) => (
                        <div
                          className="py-4 bg-gray-100 rounded"
                          key={milestone.id + "completed"}
                        >
                          <div className="p-4">
                            <h1 className="tracking-tight font-bold text-gray-900 text-xl">
                              {milestone.name}
                            </h1>

                            {milestone.type == "event" ? (
                              <Link
                                to={"/courses/figma/event"}
                                target="_blank"
                                state={{
                                  eventName: milestone.id,
                                  userData: userData,
                                  cohortNum: cohortNum,
                                }}
                                className="tracking-tight font-bold text-blue-700 text-xl mb-3 hover:opacity-75"
                              >
                                View recording &#8250;
                              </Link>
                            ) : (
                              <h1 className="tracking-tight font-bold text-gray-900 text-xl">
                                {milestone.title}
                              </h1>
                            )}

                            {milestone.demo ? (
                              <a
                                target="_blank"
                                href={milestone.demo}
                                className="text-blue-700 text-sm block font-bold hover:opacity-75"
                              >
                                View submission
                                {milestone.img ? (
                                  <img
                                    className="h-16 rounded mt-2"
                                    src={milestone.img}
                                  />
                                ) : null}
                                <span className="block text-xs text-gray-700 mt-2">
                                  {" "}
                                  {new Date(
                                    milestone.submittedDate
                                  ).toLocaleString()}{" "}
                                </span>
                              </a>
                            ) : (
                              <a>
                              {milestone.img ? (
                                  <img
                                    className="h-16 rounded mt-2"
                                    src={milestone.img}
                                  />
                                ) : null}
                                <span className="block text-xs text-gray-700 mt-2">
                                  {" "}
                                  {new Date(
                                    milestone.submittedDate
                                  ).toLocaleString()}{" "}
                                </span>
                              </a>
                            )}
                          </div>
                        </div>
                      ))}
                </section>
                <ResourceLinks title="Resources" fields={resources} />
              </div>
            </div>
          ) : (
            <></>
          )}
        </section>
      </div>
    </>
  );
};

export default FigmaCourse;
